* {
  box-sizing: border-box;
}
html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 0;
  background-color: #1c7299;
  font-family: "Kosugi Maru", sans-serif;
  scrollbar-width: none;
  position: relative;
  &::-webkit-scrollbar {
    width: 8px;
    top: 0;
    right: 0;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    background-color: #81ccd9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
}

@keyframes avatar-animation {
  0% {
    transform: scale(0);
    transform-origin: bottom;
  }
  80% {
    transform: scale(1.1);
    transform-origin: bottom;
  }
  100% {
    transform: scale(1);
    transform-origin: bottom;
  }
}

@keyframes repley-animation {
  0% {
    transform: scale(0);
    transform-origin: left bottom;
  }
  100% {
    transform: scale(1);
    transform-origin: left bottom;
  }
}

@keyframes me-animation {
  0% {
    transform: scale(0);
    transform-origin: right bottom;
  }
  100% {
    transform: scale(1);
    transform-origin: right bottom;
  }
}

@keyframes fade-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.auth-btn {
  button {
    border: none;
    font-weight: bolder;
    position: relative;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-radius: 2px;
    max-width: 350px;
    &:hover {
      opacity: 0.8;
    }
    img {
      height: 32px;
      margin-right: 8px;
    }
  }
  &.email-password-btn button {
    background-color: #65c8f1;
    padding: 10px;
    font-size: 16px;
    color: #fff;
  }
  &.google-btn button {
    background-color: #fff;
    color: #000;
    box-shadow: 0 1px 2px #777;
    img {
      width: 21px;
    }
  }
  &.twitter-btn button {
    background-color: #1da1f2;
    color: #fff;
    img {
      width: 30px;
    }
  }
  &.facebook-btn button {
    background-color: #1873eb;
    color: #fff;
    img {
      width: 35px;
    }
  }
}

.text-box {
  display: block;
  border: solid 1px #aaa;
  padding: 0.75em 5px;
  box-sizing: border-box;
  width: min(100%, 350px);
  margin: 0 auto 16px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 16px;
  border-radius: 5px;
  &:focus {
    border: 1px solid #ff9900;
    z-index: 10;
    outline: 0;
  }
}
.auth-or {
  text-align: center;
  position: relative;
  color: #ccc;
  font-size: 14px;
  margin: 24px 0;
  position: relative;
  &::before {
    content: "";
    border-bottom: solid 1px #ccc;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: min(100%, 350px);
    z-index: 1;
  }
  span {
    position: relative;
    z-index: 2;
    padding: 0 16px;
    background-color: #fff;
  }
}
.messagesContainer {
  margin: 0 auto;
  max-width: 768px;
  padding: 40px 64px 60px;
  display: flex;
  flex-direction: column;
  .talk {
    margin-bottom: 40px;
    flex-direction: column;
    display: flex;
    opacity: 0;
    &.loaded {
      opacity: 1;
    }
    .talkText {
      padding: 4px 16px 3px;
      background-color: #fff7;
      border: solid 3px #fff;
      border-radius: 4px 5px 5px 4px;
      width: fit-content;
      font-size: 1.2em;
      line-height: 1.3em;
      text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
        1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff,
        1px 0 0 #fff;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
      position: relative;
      time {
        position: absolute;
        font-size: 0.6em;
        bottom: 42px;
        width: 100%;
        left: 0;
        text-align: center;
      }
      a {
        display: inline-block;
        text-shadow: none;
      }
      &::before {
        content: "";
        position: absolute;
        background: no-repeat 100% / contain url("../public/balloon-parts.svg");
        height: 26px;
        width: 17.5px;
        bottom: -3px;
      }
      &::after {
        content: "";
        position: absolute;
        top: -2.6px;
        background-color: #fff;
        width: 3px;
        height: calc(100% - 16px);
      }
      .user-block {
        position: absolute;
        bottom: -12px;
        img.talkIcon {
          user-select: none;
          border-radius: 50%;
          opacity: 0.9;
        }
      }
    }
    .talkImage {
      max-width: 90%;
      height: auto;
      margin-top: 18px;
      border-radius: 7px;
      background-color: #1f5770;
      border: solid 2px #fff;
      cursor: pointer;
    }
    .back-ground {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: max(100%, 100vh);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 1;
      transition: all 0.4s ease 0s;
      z-index: -1;
    }
    &.new {
      .talkText,
      .talkImage,
      .user-block {
        transform: scale(0);
      }
      .user-block {
        animation: avatar-animation 0.3s;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
      }
      .back-ground {
        animation: fade-animation 0.3s;
      }
    }
    &.hidden .back-ground {
      opacity: 0 !important;
    }
    &.reply {
      align-items: flex-start;
      .talkText {
        border-left-color: transparent;
        margin-left: 16px;
        &.is-ios::before {
          left: -16.75px;
        }
        &.defult::before {
          left: -17.5px;
        }
        &::after {
          border-radius: 3px 0;
          left: -3px;
        }
        .user-block {
          left: -63px;
          img.talkIcon {
            transform: scale(-1, 1);
          }
        }
      }
      &.new {
        .talkText,
        .talkImage {
          transform: scale(0);
          animation: repley-animation 0.25s;
          animation-delay: 0.6s;
          animation-fill-mode: forwards;
        }
        .user-block {
          transform: scale(0) rotateY(180deg);
        }
      }
    }
    &.me {
      align-items: flex-end;
      .talkText {
        border-right-color: transparent;
        margin-right: 18px;
        &.is-ios::before {
          right: -16.75px;
          transform: scale(-1, 1);
        }
        &.defult::before {
          right: -17.5px;
          transform: scale(-1, 1);
        }
        &::after {
          border-radius: 0 3px;
          right: -3px;
        }
        .user-block {
          right: -63px;
          img.talkIcon {
            cursor: pointer;
          }
        }
      }
      &.new {
        .talkText,
        .talkImage {
          transform: scale(0);
          animation: me-animation 0.25s;
          animation-delay: 0.6s;
          animation-fill-mode: forwards;
        }
        .user-block {
          transform: scale(0);
        }
      }
    }
  }
}

@keyframes loading {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.isLoading {
  background-color: #0007;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 99;
  padding-bottom: 70px;
  svg {
    animation: loading 1.2s infinite linear;
    color: #fff;
  }
}

.sendForm {
  z-index: 3;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(#aaa, #666);
  .formContainer {
    margin: 0 auto;
    max-width: 768px;
    padding: 9px 15px 13px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .arrow-btn {
      cursor: pointer;
      padding-bottom: 3px;
    }
    label.imageLabel {
      padding-right: 4px;
      margin-right: 4px;
      display: block;
      cursor: pointer;
      input[type="file"] {
        display: none;
      }
    }
    .textArea {
      line-height: 20px;
      font-size: 16px;
      width: calc(100% - 10px);
      border: none;
      padding: 7px 14px;
      background-color: #fafafa;
      border-radius: 3px;
      font-family: "Kosugi Maru", sans-serif;
      resize: none;
      margin: 0 5px;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #ccc;
        font-size: 0.8em;
      }
    }
    .sendBtn {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .more-horiz-btn {
      margin: 1px 9px 1px 10px;
      cursor: pointer;
    }
    .image-view {
      color: #fff;
      position: relative;
      img {
        border: solid 1px #fff;
      }
      button {
        position: absolute;
        right: -3px;
        top: -10px;
        z-index: 2;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        svg {
          max-height: 18px;
          max-width: 18px;
        }
      }
    }
  }
  .ex-messages {
    position: absolute;
    bottom: 100%;
    background-color: #fff;
    width: 100%;
    padding: 5px 15px;
    height: 250px;
    overflow-y: scroll;
    .ex-messages-inner {
      margin: 0 auto;
      max-width: 700px;
      position: relative;
      p {
        text-align: center;
        border-bottom: solid 1px #ccc;
        padding: 16px 0;
        margin: 0;
        cursor: pointer;
      }
    }
    .close-ex-messages-btn {
      position: absolute;
      top: 5px;
      right: 0;
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.switching-credential {
  display: flex;
  justify-content: center;
  button {
    background-color: #f38415;
    width: 150px;
    color: white;
    font-size: 16px;
    border: none;
    padding: 10px 0;
    border-radius: 5px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #f3912e;
    }
  }
}
.modal-overlay {
  background-color: #3338;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  cursor: pointer;
}
.modal-content {
  background-color: #fff;
  border-radius: 15px;
  position: fixed;
  width: min(600px, 90%);
  top: 50%;
  max-height: 90vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 9999;
  opacity: 0;
  animation: fade-animation 0.5s;
  animation-fill-mode: forwards;
  padding: 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .modal-close-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }
  h1 {
    text-align: center;
    margin-bottom: 56px;
    font-size: 1.5em;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  button {
    font-size: 16px;
    border: none;
    font-weight: bolder;
    padding: 10px 25px;
    border-radius: 5px;
    transition: 0.2s;
    cursor: pointer;
  }
}

.singin-btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fffa;
  button {
    background-color: #65c8f1;
    color: white;
    &:hover {
      background-color: #7fd1f4;
    }
  }
}

.close-btn {
  button {
    background-color: #65c8f1;
    color: white;
    &:hover {
      background-color: #7fd1f4;
    }
  }
}
.logout-btn {
  border: none;
  display: block;
  background-color: transparent;
  color: #1873eb;
  text-decoration: underline;
  font-weight: normal;
  width: fit-content;
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    color: #00a0ff;
  }
}
.avatar-cancel-btns button {
  background-color: #ccc;
  color: white;
  font-weight: bolder;
  margin-right: 8px;
  &:hover {
    background-color: #ddd;
  }
}
.avatar-submit-btn button {
  background-color: #65c8f1;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
  &:hover {
    background-color: #7fd1f4;
  }
}
.user-avatar-view {
  width: fit-content;
  margin: 0 auto 24px;
  position: relative;
  img {
    border-radius: 50%;
    border: solid 2px#6BC9F1;
    cursor: pointer;
    height: auto;
  }
  input {
    display: none;
  }
  .edit-icon {
    position: absolute;
    bottom: -2px;
    right: -2px;
    font-size: 1.1em;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    background-color: #aaa;
    cursor: pointer;
  }
}
.user-name {
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 48px;
}
.edit-btn {
  cursor: pointer;
}
.name-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 16px auto 48px;
  .text-box {
    margin-right: 8px;
    margin-bottom: 0;
  }
}
.crop-wrapper {
  padding-bottom: 50vh;
  position: relative;
  .reactEasyCrop_Container {
    .reactEasyCrop_CropArea {
      border-color: #00a0ff;
    }
  }
}
.controls {
  padding: 20px 0;
  z-index: 10000;
  background-color: #fffc;
  input[type="range"] {
    width: 100%;
    margin-bottom: 30px;
  }
  .crop-btns {
    flex-wrap: wrap-reverse;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
